<template>
  <div>
    <span id="disabled-wrapper" class="d-inline-block" tabindex="0">
      <b-button v-b-modal.modal-new-deposit variant="success" :disabled="depositIsPending">
        <span class="svg-icon svg-icon-md svg-icon-white">
          <inline-svg src="/media/svg/icons/Shopping/Barcode.svg" />
        </span>
        Novo depósito
      </b-button>
    </span>
    <b-tooltip v-if="depositIsPending" variant="danger" target="disabled-wrapper">
      Você tem um depósito pendente, para criar outro é necessário concluir ou cancelar!
    </b-tooltip>

    <!-- Modal new Deposit -->
    <b-modal id="modal-new-deposit" title="Novo Depósito" modal hide-footer no-stacking>
      <NewDeposit />
    </b-modal>

    <!-- Modal new Deposit Confirmation -->
    <b-modal id="modal-deposit-confirmation" title="Novo Depósito" modal hide-footer no-stacking>
      <ConfirmationDeposit />
    </b-modal>
  </div>
</template>

<script>
import NewDeposit from '@/screens/Financial/components/Real/Deposit/NewDeposit';
import ConfirmationDeposit from '@/screens/Financial/components/Real/Deposit/ConfirmationDeposit';

import { mapGetters } from 'vuex';

export default {
  name: 'NewDepositButton',
  components: {
    NewDeposit,
    ConfirmationDeposit
  },
  computed: {
    ...mapGetters(['depositIsPending'])
  }
};
</script>

<style></style>
