<template>
  <div class="card-body p-0">
    <div class="row">
      <div class="col-lg-12">
        <div class="alert alert-info mb-5 p-5" role="alert">
          <h4 class="alert-heading">Informações importantes!</h4>
          <p>
            Para realizar o depósito de reais em sua carteira é muito simples, informe o valor no campo abaixo para que
            possamos gerar sua fatura de depósito.
          </p>
        </div>

        <b-form class="form" @submit.stop.prevent="onSubmit">
          <h3 class="font-size-lg text-dark font-weight-bold mb-6">Informe o valor que deseja depositar:</h3>
          <!-- Value input -->
          <b-form-group>
            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
              <money
                v-model="$v.form.value.$model"
                v-bind="maskMoneyReal()"
                class="form-control form-control-solid h-auto py-5 px-6 rounded"
                :class="$v.form.value.$invalid ? 'is-invalid' : 'is-valid'"
                :state="validateState('value')"
              />
              <b-form-invalid-feedback v-if="!$v.form.value.minValue">Insira um valor</b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>

          <!-- Method -->
          <h3 class="font-size-lg text-dark font-weight-bold mb-6">Selecione como deseja depositar:</h3>

          <div v-if="!isLoading">
            <b-form-group v-for="gateway in invoiceGateways" :key="gateway.id">
              <!-- <span class="text-info font-weight-bold">{{ gateway.description }}</span> -->
              <div class="custom-radio-square">
                <div v-for="method in gateway.methods" :key="method.id" class="radio-tile-group">
                  <div class="input-container">
                    <input
                      id="walk"
                      v-model="$v.form.method_id.$model"
                      class="radio-button"
                      type="radio"
                      name="method_id"
                      :value="method.id"
                      :state="validateState('method_id')"
                    />
                    <div class="radio-tile">
                      <span class="icon">
                        <span class="svg-icon svg-icon-dark svg-icon-2x">
                          <inline-svg :src="'/media/svg/icons/Shopping/' + method.icon + '.svg'" />
                          <!--end::Svg Icon-->
                        </span>
                      </span>
                      <label for="walk" class="radio-tile-label">{{ method.name }}</label>
                    </div>
                  </div>
                </div>
              </div>
              <b-form-invalid-feedback :style="!$v.form.method_id.$model ? 'display:block' : ''">
                O campo bairro é obrigatório.
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div v-else class="font-size-xs text-dark mb-6 d-flex align-items-center">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
            Carregando Gateways..
          </div>
          <!--begin::Action-->
          <div class="form-group d-flex justify-content-center border-top pt-10">
            <button
              ref="btn_submit"
              type="submit"
              class="btn btn-success font-weight-bold px-12 py-4 my-3 font-size-3 mx-4"
              :disabled="isSubmitting"
              :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
            >
              Continuar
            </button>
          </div>
          <!--end::Action-->
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-3.scss';
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, minValue } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { Money } from 'v-money';
// import ModalPayInvoice from '@/shared/components/invoice/ModalPayInvoice';

export default {
  name: 'NewDeposit',
  components: { Money },
  mixins: [validationMixin],
  data() {
    return {
      isSubmitting: false,
      isLoading: false,
      form: {
        value: 0,
        method_id: 1
      },
      loader: {
        show: false,
        message: '',
        timing: false
      }
    };
  },
  computed: {
    ...mapGetters(['invoiceGateways'])
  },
  async mounted() {
    this.isLoading = true;
    this.$store.commit('SET_NEW_DEPOSIT', this.form);
    await this.$store.dispatch('GET_INVOICE_PARAMS');
    this.isLoading = false;
  },
  validations: {
    form: {
      value: {
        required,
        minValue: minValue(1)
      },
      method_id: {
        required
      }
    }
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        this.isSubmitting = false;
        return;
      }

      await this.$store.commit('SET_NEW_DEPOSIT', this.form);
      this.$bvModal.show('modal-deposit-confirmation');
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>
