<template>
  <div>
    <ul class="nav nav-dark nav-bold nav-tabs nav-tabs-line" data-remember-tab="tab_id" role="tablist">
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive('pending_and_confirm') }"
          href="#pending_and_confirm"
          @click.prevent="setActive('pending_and_confirm', '1,2')"
        >
          Pendentes e Confirmados
        </a>
      </li>
      <li class="nav-item">
        <a
          class="nav-link"
          :class="{ active: isActive('canceled') }"
          href="#canceled"
          @click.prevent="setActive('canceled', '3,4,5')"
        >
          Cancelados e Estornados
        </a>
      </li>
    </ul>

    <div class="table-responsive">
      <b-table
        responsive
        class="table table-head-custom table-vertical-center"
        :items="pageOfItems"
        :per-page="perPage"
        :fields="fields"
        :busy="isLoading"
        stacked="sm"
        hover
        show-empty
      >
        <!-- Empty table -->
        <template #empty>
          <EmptyTable message="Você ainda não realizou nenhum depósito." />
        </template>

        <!-- Busy table -->
        <template #table-busy>
          <BusyTable message="Aguarde enquanto carregamos seus depósitos." />
        </template>

        <template v-slot:cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #cell(created_at)="row">
          <span>
            <div class="font-weight-bolder text-success mb-0 font-size-lg">
              {{ $moment(row.item.created_at).format('DD/MM/YYYY') }}
            </div>
            <div class="text-muted">{{ $moment(row.item.created_at).format('hh:mm:ss') }}</div>
          </span>
        </template>

        <template #cell(id)="row">
          <span class="font-weight-bolder text-success mb-0 font-size-lg">{{ row.item.id }}</span>
        </template>

        <template #cell(final_amount)="row">
          <span>
            {{ row.item.final_amount | currency('R$ ') }}
          </span>
        </template>

        <template #cell(status)="row">
          <span class="badge" :class="'badge-' + row.item.status.class">{{ row.item.status.name }}</span>
        </template>

        <template #cell(actions)="row">
          <InvoiceActions :invoice="row.item" />
        </template>
      </b-table>
      <pagination
        v-if="!isLoading && depositList.length > 0 && isPagination"
        :items="depositList"
        :page-size="perPage"
        @changePage="onChangePage"
      ></pagination>
    </div>
  </div>
</template>

<script>
import Pagination from '@/shared/content/widgets/table/Pagination';
import { mapGetters } from 'vuex';
import InvoiceActions from '@/shared/components/invoice/InvoiceActions';
import EmptyTable from '@/shared/components/extras/EmptyTable';
import BusyTable from '@/shared/components/extras/BusyTable';

export default {
  name: 'TableDepositList',
  components: {
    Pagination,
    InvoiceActions,
    EmptyTable,
    BusyTable
  },

  props: {
    limit: {
      type: Number,
      default: null
    },
    isPagination: {
      type: Boolean,
      default: true
    },
    showFooter: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      isLoading: false,
      isError: false,
      activeItem: 'pending_and_confirm',
      perPage: 10,
      fields: [
        { label: 'Data', key: 'created_at', sortable: true },
        { label: 'ID', key: 'id', sortable: true },
        { label: 'Valor', key: 'final_amount', sortable: true },
        { label: 'Status', key: 'status', sortable: true },
        { label: 'Ações', key: 'actions' }
      ],
      pageOfItems: []
    };
  },
  computed: {
    ...mapGetters(['depositList'])
  },
  watch: {
    async depositList() {
      this.pageOfItems = await this.depositList;
    }
  },
  async mounted() {
    this.isLoading = true;

    await this.$store.dispatch('GET_DEPOSIT_LIST', { status_id: '1,2' }).catch(() => {
      this.pageOfItems = [];
      this.isLoading = false;
      this.isError = true;
    });
    await this.$store.dispatch('GET_INVOICE_PARAMS');

    if (!this.isPagination) {
      this.pageOfItems = this.depositList;
    }

    this.isLoading = false;
  },
  methods: {
    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    async setActive(menuItem, status_id) {
      this.isLoading = true;
      this.activeItem = menuItem;
      await this.$store.dispatch('GET_DEPOSIT_LIST', { status_id: status_id });
      this.isLoading = false;
    }
  }
};
</script>

<style></style>
