<template>
  <div>
    <div class="card card-custom bg-light gutter-b">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="mr-6">
            <span class="svg-icon svg-icon-4x">
              <inline-svg src="/media/svg/icons/Shopping/Barcode.svg" />
            </span>
          </div>
          <div class="d-flex flex-column text-dark-80">
            Por favor, deposite o valor exato de sua ordem como o valor a seguir, caso contrário suas transações não
            serão efetuadas. xs
            <h4 class="text-primary mt-2">
              {{ depositNew.value | currency() }}
              <small>BRL</small>
            </h4>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom bg-light gutter-b">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="mr-6">
            <span class="svg-icon svg-icon-4x">
              <inline-svg src="/media/svg/icons/General/scan.svg" />
            </span>
          </div>
          <div class="d-flex flex-column text-dark-80">
            Aceitaremos apenas transferências de fundos da Conta Bancária e CPF do mesmo titular:
            <h4 class="text-primary mt-2">{{ currentCustomer.name.toUpperCase() }}</h4>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom bg-light gutter-b">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="mr-6">
            <span class="svg-icon svg-icon-4x">
              <inline-svg src="/media/svg/icons/Shopping/Wallet2.svg" />
            </span>
          </div>
          <div class="d-flex flex-column text-dark-80">
            Por favor, não use contas corporativas com CNPJ para efetuar a transação, pois esta ordem foi criada através
            de uma conta pessoal e CPF.
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom bg-light gutter-b">
      <div class="card-body">
        <div class="d-flex align-items-center">
          <div class="mr-6">
            <span class="svg-icon svg-icon-4x">
              <inline-svg src="/media/svg/icons/Shopping/Safe.svg" />
            </span>
          </div>
          <div class="d-flex flex-column aling-items-center text-dark font-weight-bolder">
            O PIX está disponível 24h por dia, todos os dias (Recomendado).
          </div>
        </div>
      </div>
    </div>

    <b-form class="form" @submit.stop.prevent="onSubmit">
      <button
        type="submit"
        class="btn btn-success btn-block font-weight-bold px-12 py-4 my-3 font-size-3"
        :disabled="isSubmitting"
        :class="{ 'spinner spinner-light spinner-right': isSubmitting }"
      >
        Realizar Depósito
      </button>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isSubmitting: false,
      form: null
    };
  },
  computed: {
    ...mapGetters(['depositNew', 'currentCustomer'])
  },
  mounted() {
    this.form = this.depositNew;
  },
  methods: {
    async onSubmit() {
      this.isSubmitting = true;

      // send register request
      await this.$store
        .dispatch('STORE_DEPOSIT', this.depositNew)
        .then((response) => {
          this.$swal({
            title: response.success,
            icon: 'success'
          });

          this.$bvModal.show('modal-pay-invoice');
          this.$store.dispatch('GET_DEPOSIT_LIST', { params: { status_id: '1,2' } });
        })
        .catch((error) => {
          this.$bvModal.hide('modal-new-deposit');
          this.$swal({
            title: error,
            icon: 'error'
          });
        });
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    }
  }
};
</script>

<style></style>
