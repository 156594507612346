<template>
  <div class="flex-row-fluid col-lg-9">
    <div class="card card-custom gutter-b">
      <div class="card-header py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">Meus Depósitos</span>
          <span class="text-muted mt-3 font-weight-bold font-size-sm">Acompanhe abaixo seus depósitos</span>
        </h3>
        <div class="card-toolbar">
          <NewDepositButton />
        </div>
      </div>
      <div class="card-body">
        <TableDepositList />
      </div>

      <ModalPayInvoice v-if="selectedInvoice" title="Depósito" />
      <ModalPayMethodSelect v-if="selectedInvoice" />
      <ModalReceipt v-if="selectedInvoiceReceipt" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TableDepositList from '@/shared/components/deposit/TableDepositList';
import NewDepositButton from '@/shared/components/deposit/NewDepositButton';
import invoiceModalsMixin from '@/core/mixins/invoiceModalsMixin';

export default {
  name: 'ListDeposits',
  components: {
    TableDepositList,
    NewDepositButton
  },
  mixins: [invoiceModalsMixin],
  computed: {
    ...mapGetters(['selectedInvoice', 'selectedInvoiceReceipt'])
  },
  async mounted() {
    this.$store.dispatch('SET_BREADCRUMB', [
      { title: 'Carteiras' },
      { title: 'Carteira Reais' },
      { title: 'Depósitos' }
    ]);
  }
};
</script>

<style></style>
